<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p><i  class="pi pi-fw pi-info-circle"></i> Se gestiona todos las Usuario con sus dependencias</p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="openNew()"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          selectionMode="single"
          dataKey="id"
          sortField="orden"
          :sortOrder="1"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items"
          responsiveLayout="scroll"
          :globalFilterFields="[
            
            'nombre',
            'dependencia'
           
          ]"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="idEjecutora" header="Id" :sortable="true" headerStyle="width: 3rem">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              <b>{{ slotProps.data.idEjecutora }}</b>
            </template>
          </Column>
         

          <Column
            field="nombre"
            header="Dependencia"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Dependencia</span>
              {{slotProps.data.dependencia}}
            </template>
          </Column>

          <Column
            field="nombre"
            header="Usuario"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Usuario</span>
              {{slotProps.data.nombre}}
            </template>
          </Column>
          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                @click="[itemClick(slotProps.data), toggle($event)]"
              />
              <Menu ref="menu" :model="items" :popup="true">
                <template #item="{ item }">
                  <Menuitem :rel="slotProps">
                    <li>{{ item.label }}</li>
                  </Menuitem>
                </template>
              </Menu>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '550px' }"
          header="Detalles del usuario o dependencia o sede"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Elegir Dependencia</label>
            <Dropdown
              v-model="selectEjecutora"
              required="true"
              :options="ejecutora"
              :filter="true"
              optionLabel="nombre"
              :disabled="isBussy"
              placeholder="Seleccione la sede o dependencia"
              :class="{ 'p-invalid': submitted && !selectEjecutora }"
              filterPlaceholder="Busque la dependencia"
            />
            <small class="p-invalid" v-if="submitted && !selectEjecutora"
              >Seleccione la dependencia o sede.</small
            >
          </div>
          <div class="p-field">
            <label for="name">busque el login</label>
            <InputText
              id="name"
              v-model.trim="login"
              :disabled="isBussy"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !login }"
            />
            <small class="p-invalid" v-if="submitted && !login"
              >Login es requerido.</small
            >
          </div>
          <div class="p-field">
            <Button
              label="Buscar"
              icon="pi pi-search"
              class="p-button-text"
               @click="buscarLogin"
            
            />
            
          </div>
          <div class="p-field">
            <label for="name">Nombre y apellido</label>
            <InputText
              disabled="true"
              v-model.trim="nombre"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !nombre }"
            />
            <small class="p-invalid" v-if="submitted && !nombre"
              >Nombre y apellido.</small
            >
          </div>
          <div class="p_field">
            <label class="form-label">Admin: </label>
                <Checkbox  v-model="jefe" value = "si" />
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmar"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="selectedProducts"
              >Está seguro de querer borrar el item:
              <b>{{ selectedProducts.id }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {

  data() {
    return {
      page: 1,
      numPages: 0,
      errors: [],
      scale: "page-width",
      selectEjecutora : null,
      login : null,
      ejecutora: {},
      selectUser : null,
      jefe : false,
      nombre : null,
      items: [
        {
          label: "Opciones",
          items: [
           
            {
              label: "Editar",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                this.editProduct(this.selectedProducts);
              },
            },
 
            {
              label: "Borrar",
              icon: "pi pi-fw pi-trash",
              command: () => {
                this.confirmDeleteProduct()
              },
            },
          ],
        },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      product: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombreObra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      isBusy: false,
      submitted: false,
      action: "gstupa/DEPENDENCIA_SAVE",
    };
  },
  
  methods: {
    buscarLogin (){
      if(this.login){
        this.isBussy = true;
        this.$store
          .dispatch("gsdialogoauth/infologin", this.login)
          .then((re) => {
            this.isBussy = false;
            
            this.nombre = re.personal.nombre+ " "+re.personal.apellidoPaterno + " "+ re.personal.apellidoMaterno
            this.selectUser = re

          })
          .catch((err) => {
            this.isBussy = false;
            console.info(err)
          });
        }
    },
    itemClick(data) {
      this.selectedProducts = data;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    myUploader(file) {
      /// console.info(file.files[0]);

      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.tipoArchivo = file.files[0].type;
              this.product.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    saveProduct() {
      this.submitted = true;
      let modelo = {
        usuaId : this.selectUser.idUsuario,
        dedeId : this.selectEjecutora.idEjecutora,
        dependencia: this.selectEjecutora.nombre,
        nombre : this.selectUser.personal.nombre+ " "+this.selectUser.personal.apellidoPaterno + " "+ this.selectUser.personal.apellidoMaterno,
        jefe :  this.jefe.length > 0 ? true : false
      }
      
       if (
        this.nombre && this.selectUser) {
           this.isBusy = true;
           this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gstupa/DEPENDENCIA_USUA_DEPE_SAVE", modelo)
              .then((res) => {
                this.productDialog = false;
                this.isBusy = false;
                this.$swal.close();
                this.listarUsuaDepe();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Usuario Dependencia Actualizado id: " + res.id,
                  life: 3000,
                });

                this.$store
                .dispatch("gsdialogoauth/saveperfil", this.selectUser.idUsuario)
                .then((re) => {
                  this.isBussy = false;
                  
                  this.nombre = re.personal.nombre+ " "+re.personal.apellidoPaterno + " "+ re.personal.apellidoMaterno
                  this.selectUser = re

                })
                .catch((err) => {
                  this.isBussy = false;
                  console.info(err)
                });

              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                this.error(error)
              });
          }}).then((result) => {
          console.log(result);
        });
        }
    },
    listarEjecutora(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/DEPENDENCIA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.ejecutora = res;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    listarUsuaDepe() {
      let params = {
      };
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/DEPENDENCIA_USUA_DEPE_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
  
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
    openNew() {
      this.action = "gstupa/DEPENDENCIA_SAVE"
      this.product = {
        idEjecutora: 0,
        nombre: "",
        abreviatura: "",
        orden: this.products.length+1,
        estado: true
      };
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
   
    open(operacion) {
      if (operacion == "new") {
        this.product = {
          orden: this.products.length+1,
          estado: true,
        };
      }
      let mutacion = {
        tupa: this.product,
        oper: operacion,
      };
      this.$store.commit("gstupa/OPERACION_DEPENDENCIA", mutacion);
      this.$router.push({
        name: "tupa/amigable/" + operacion,
      });
    },
   
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.product = this.selectedProducts;
      this.deleteProductDialog = false;
      this.isBusy = true;

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/DEPENDENCIA_DELETE", this.product.id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = this.products.filter(
                (val) => val.id !== this.product.id
              );
              this.product = {};
              //this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Usuario Dependencia borrada, número: " + res.numero,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    editProduct(product) {
      this.action = "gstupa/DEPENDENCIA_UPDATE";
      this.product = { ...product };
      this.productDialog = true;
    },
  },
  computed: {
    ...mapGetters({
      tupa: "gstupa/getModelUsuaDepe",
      user: "gsdialogoauth/getUser",
    }),
  },

  mounted() {
   
    this.listarUsuaDepe();
    this.listarEjecutora("");

  }
};
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>

